/* auth page css i.e. login and register*/
/*html, body, #root {
    height: 100%;
}

body{
    background: #E9EBED;
    line-height: 1.5;
    font-size: 1.5em;
    font-family:"Helvetica Neue", Helvetica, Arial, sans-serif,Roboto;
}*/

.au-btn {
  color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-weight: 800;
}

.au-btn:hover,
.au-btn:focus {
  color: #ffffff;
}

input[type="date"],
input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
  /* background-color: #e9ebed; */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
}

input:focus,
select:focus {
  outline: #00c9ca solid 1px;
  outline-offset: 0px;
}

.form-control {
  height: 32px;
}

.input-group-cust {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
}

.theme-register {
  background-color: #712f91;
}

.theme-login {
  background-color: #ff807f;
}

.theme-forgetPassword {
  background-color: #ffac31;
}

.fa-forgot-clr {
  color: #ffac31;
}

.theme-resetPassword {
  background-color: #689f38;
}

.fa-reset-clr {
  color: #689f38;
}

.input-group img {
  width: 16px;
}

.au-frame {
  -webkit-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  -moz-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  margin: auto;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.au-login-frame {
  width: 300px;
  max-width: 350px;
  height: 350px;
  max-height: 500px;
  overflow: hidden;
}

.au-reg-frame {
  width: 355px;
  max-width: 350px;
  height: 500px;
  max-height: 545px;
  transition: all 100ms linear;
  overflow: auto;
}

.au-form {
  transition: all 0.3s ease;
  padding: 22px 22px 14px 22px;
}

.au-group {
  margin-bottom: 16px;
}

.au-banner {
  height: 80px;
  padding: 8px;
}

.au-banner span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
}

.au-banner div img {
  width: 30px;
}

.au-bcd {
  margin: -16px;
}

.fa-reg-clr {
  color: #712f91;
}

.fa-log-clr {
  color: #ff807f;
}

.au-banner-ico {
  position: absolute;
}

.au-dfg {
  position: relative;
}

.au-bfg {
  margin: 3% 0;
}

.au-checkbox {
  display: flex;
  padding: 0 14px;
}

.au-sta {
  line-height: normal;
  vertical-align: middle;
}

.au-text {
  font-size: 13px;
}

.au-re {
  margin-bottom: 10px;
}

.checkbox {
  padding-bottom: 14px;
}

/* top header css*/

.navbar {
  min-height: 50px;
  color: #ffffff;
  font-size: 1em;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.navbar a {
  color: #ffffff;
}

.top-header {
  margin: 0;
}

.top-header span {
  font-size: 28px;
  font-weight: 900;
  line-height: 50px;
  vertical-align: middle;
}

.bottom-header {
  min-height: 40px;
  background-color: #00465b;
  font-weight: 500;
  font-size: 0.85em;
  margin: 0;
}

.bg-primary {
  background-color: #00c9cb;
}

.bg-primary-text {
  color: #00c9cb;
}

.header-item {
  list-style: none;
}

.bottom-header .container {
  padding-left: 0;
  padding-right: 0;
}

.bottom-header li a:hover,
.bottom-header li a.active,
.bottom-header li a:focus,
.bottom-header .open > a,
.bottom-header .open > a:hover,
.bottom-header .open > a:focus {
  background-color: #00c9ca;
  color: rgba(0, 0, 0, 0.62);
}

.bottom-header .dropdown-menu li a {
  color: rgba(0, 0, 0, 0.65);
}

.bottom-header li a:focus,
.bottom-header li a:hover {
  color: rgba(0, 0, 0, 0.62);
}

.bottom-header .dropdown-menu {
  background-color: #ffffff;
}

.register-phone {
  color: #ffffff;
  font-size: 20px;
}

.circle-bg {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}

.navbar-nav > li > .menu-icon {
  padding: 10px 5px;
}

.bottom-header .wide-dropdown {
  width: 740px;
  background-color: #00c9cb;
}

.bottom-header .wide-dropdown li a {
  color: #f9f9f9;
}

.wide-dropdown > li {
  float: left;
}

/*profile page sidebar css*/
.card {
  -webkit-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  -moz-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  background-color: #ffffff;
  border-radius: 6px;
  width: auto;
  overflow: hidden;
}

.card .item {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  padding: 12px;
}

.card .item:hover,
.card .item:focus,
.card .item.active {
  background-color: #00c3c8;
  text-decoration: none;
}

.card .side-nav {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  padding: 12px;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .card .side-nav {
    flex-direction: column;
  }
}

.card .nav-cont:hover,
.card .nav-cont:focus,
.card .nav-cont.active {
  background-color: #00c3c8;
  text-decoration: none;
}

.card .side-nav:hover > img,
.card .side-nav:focus > img,
.card .side-nav:active > img {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.card .item-name {
  color: rgba(0, 0, 0, 0.62);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  padding-left: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  align-items: center;
  box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .card .item-name {
    padding-left: 0;
  }
}

/*profile page mainbar*/
.profdet {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 0;
}

.profdet .det {
  color: rgba(0, 0, 0, 0.62);
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  margin-left: 8px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  align-items: center;
  box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 24px;
}

.pcard {
  display: flex;
  justify-content: space-between;
}

.profdiv {
  margin: 4px 4%;
}

.profdiv h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 6px 0;
}

.profname {
  padding: 8px;
  text-align: center;
  background-color: #00465b;
  color: #ffffff;
}

.profcont {
  padding-right: 15px;
  padding-left: 15px;
}

.pfa {
  padding: 2px;
}

.profile-photo {
  flex-grow: 1;
  height: 200px;
}

.prof-pho-edit {
  height: 34px;
  background-color: rgba(0, 0, 0, 0.38);
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  line-height: 34px;
  text-align: center;
}

.prof-pho-edit:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.valb {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.media_cont {
  padding: 8px;
}

.gridalb-wrp {
  margin: 0;
}

.gridalb-wrp > video {
  width: 100%;
  height: 134px;
  display: block;
  border: 1px solid #e8e8e8;
}

.gridalb-wrp .add-btn {
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

ul.media_list {
  padding: 0;
  overflow: hidden;
}

.navbar-nav {
  margin: 0;
}

/*Post-an-ad-table css*/

.postcd {
  padding: 14px;
}

.table-striped > thead {
  background-color: #00c3c8;
  color: #ffffff;
}

.table-ad-post > tbody {
  color: rgba(0, 0, 0, 0.64);
  font-size: 14px;
}

.table-ad-post > thead {
  font-size: 15px;
}

.table-ad-post td {
  border-left: 1px solid #ffffff;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 0;
}

.pstadsb {
  padding: 5px;
  color: rgba(0, 0, 0, 0.55);
}

.pstadsrct input {
  border-radius: 14px;
  padding: 2px 14px 2px 28px;
}

.srch_pos {
  position: absolute;
  top: 56px;
  right: 50px;
  z-index: 1;
  font-size: 15px;
}

.pstadsrct {
  position: relative;
  box-sizing: border-box;
}

.pstadsrct > i {
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
}

.pstbtnct {
  margin-bottom: 8px;
}

.btn-post-ad {
  border-radius: 16px;
  background-color: #00c3c8;
  color: white;
  font-size: 12px;
}

.btn-post-ad:hover,
.btn-post-ad:focus,
.btn-post-ad:active {
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/*post-ad-form*/
.post-form input,
.post-form textarea {
  width: 235px;
  min-width: 235px;
  padding-right: 8px;
  padding-left: 8px;
  background-color: #e9ebed;
}

.post-form textarea {
  width: 400px;
  max-width: 400px;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  height: auto;
}

.post-form .form-group {
  margin-bottom: 18px;
}

.cross-bg {
  padding: 2px;
  border: 1px solid #00c3c8;
  color: #00c3c8;
}

.frm-lbl span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.72);
}

/*messages chat css*/

._dflex {
  display: flex;
}

.cht_per {
  padding: 8px 16px;
}

.cht_per_crd {
  margin-bottom: 20px;
}

.chat-area {
  flex: 1 1 0;
}

.custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}

.inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: #00c9cb;
  border-radius: 3px;
}

.thread-component {
  overflow-y: auto;
  max-height: 100%;
  padding: 0 14px;
}

.cht-form {
  position: relative;
  padding: 4px;
}

.cht-form > input[type="text"] {
  width: 80%;
  background-color: #fff;
}

.cht-form > input[type="text"]:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.cht-send-cnt {
  display: flex;
  position: absolute;
  right: 2%;
  top: 20%;
}

.cht-btn {
  border: none;
  background-color: transparent;
}

.usr_nav_card {
  overflow-y: auto;
}

.cht_per_det {
  margin-left: 8px;
  padding: 5px;
  word-wrap: break-word;
}

.acc_per {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.82);
}

.acc_per_dep {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.37);
}

.circ-ico {
  border-radius: 50%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}

._cht_per_ico_cont {
  position: absolute;
  bottom: 2px;
}

/*.cht_cont{*/
/*margin-left: 35px;*/
/*}*/

.cht_msg {
  border-radius: 4px;
  overflow: hidden;
  margin: 1px 0;
  word-wrap: break-word;
  font-size: 14px;
}

.cht_sendr {
  float: right;
  clear: both;
  color: #ffffff;
  max-width: 80%;
}

.cht_rec {
  float: left;
  clear: both;
  color: rgba(0, 0, 0, 0.74);
  max-width: 80%;
}

.msg_turn {
  margin-top: 15px;
  position: relative;
}

.cht-deco {
  background-color: #ffffff;
  padding: 2px 4px;
}

.cht-deco-send {
  background-color: #00c3c8;
}

.long_card {
  height: 100%;
}

.cht_nav_h > h4 {
  margin: 0;
  font-weight: 900;
}

.cht_nav_h input {
  width: 100%;
}

.cht_nav_h {
  margin: 0 auto;
  padding-top: 8px;
  padding-bottom: 4px;
}

.srch-chatbox {
  padding: 0 5%;
}

.media_list li {
  list-style: none;
}

/*calendar*/
.calendar {
  height: 750px;
  margin-top: 16px;
}

.modal-title {
  float: left;
}

.fab {
  height: 20px;
  width: 20px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  padding: 22px;
  right: 40px;
  bottom: 10px;
  background-color: #00c3c8;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00c3c8;
  flex-direction: column;
  transition: box-shadow 100ms linear;
}

.fab:active {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.fab:focus {
  outline: 0;
}

.edit-profile {
  opacity: 0.6;
}

.edit-profile:hover {
  opacity: 1;
}

.proico {
  width: 100px;
  height: 24px;
  display: flex;
  justify-content: left;
  font-weight: 600;
}

.pro-img-edit {
  display: flex;
}

input.pro-name-edit {
  width: 280px;
  height: auto;
  background-color: #00465b;
}

.display-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.chat-container {
  justify-content: space-between;
  height: 100%;
}

.chat-container > div:nth-of-type(1) {
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

.chat-container > div:nth-of-type(2) {
  flex-grow: 10;
  -webkit-flex-grow: 10;
}

.chat-container > div:nth-of-type(3) {
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

.chat-date {
  clear: both;
  text-align: center;
  font-size: 12px;
  color: #565656;
}

.inner-thread-component {
  overflow: hidden;
}

.gallery-cont {
  height: 210px;
  max-height: 210px;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 8px;
  right: 12px;
  color: #bdbdbd;
  cursor: pointer;
}

.upload-video {
  margin-bottom: 8px;
}

.upload-video .add-btn {
  display: inline-block;
  align-items: center;
  justify-content: flex-end;
  color: #606060;
  cursor: pointer;
}

.upload-video .add-btn:hover {
  color: #343434;
}

.gallery-cont > div {
  display: inline-block;
  width: 100%;
  /*height: 120px;*/
  display: block;
}

.gallery-cont .video-views {
  font-size: 12px;
  color: #959595;
}

.gallery-cont .video-title {
  font-size: 14px;
  font-weight: 600;
}

.user-info {
  padding-left: 8px;
}

.label-user-info {
  font-weight: 700;
}

.heading-user-info {
  color: grey;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgba(245, 245, 245, 0.57);
}

::-webkit-scrollbar-track:hover {
  background: rgba(247, 247, 247, 0.75);
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.75);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.flex-space-between {
  justify-content: space-between;
}

.au-forgetPassword-frame {
  width: 300px;
  max-width: 350px;
  height: 260px;
  max-height: 500px;
}

.au-forgetPassword-frame {
  width: 300px;
  max-width: 350px;
  height: 280px;
  max-height: 500px;
}

.au-reset-frame {
  width: 300px;
  max-width: 350px;
  height: 290px;
  max-height: 500px;
}

/* css for chatbox */
.chatbox {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: auto;
  height: 308px;
  border-radius: 0;
  box-shadow: 0 1px 1px 0 #434343, 0 0 0 0 #dadada;
  font-size: 12px;
  background-color: #f3f3f4;
  overflow: hidden;
}

.chatbox .acc_per {
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.chatbox .cht_per_det {
  margin-left: 4px;
  word-wrap: break-word;
  padding: 0;
}

.chatbox .cht_per {
  padding: 6px 6px 6px 8px;
}

.chatbox .cht_crd {
  margin-bottom: 8px;
  border-radius: 0;
  background-color: #00c3c8;
  box-shadow: 0 1px 1px 0 #cdcdcd;
  z-index: 2;
}

.chatbox .cht_per_crd {
  margin-bottom: 8px;
}

.has-right-bar {
  padding-right: 250px;
}

.cht-media {
  display: none !important;
  background-color: transparent;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .container {
    width: 920px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 1320px) {
  .container {
    width: 1280px;
  }
}

.spin-infinite {
  animation: spin 1s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-gallery-slide img {
  margin-left: 25px;
  width: 500px;
  height: 500px;
}

.image-gallery-slide.center {
  margin-left: 10px;
  width: 500px;
  height: 500px;
}

.image-gallery-right-nav {
  right: -100px;
}
.image-gallery-left-nav {
  left: -100px;
}

.image-gallery-thumbnails.image-gallery-thumbnails-container {
  width: 110px;
  height: 110px;
}

.image-gallery-thumbnail img {
  width: 100px;
  height: 100px;
}

.image-gallery-thumbnail {
  width: 110px;
}


.table-cell-custom-padding{
  padding: 8px 16px !important;
}