html,
body,
#root {
  height: 100%;
  display: flex;
  flex-grow: 1;
}
body {
  text-rendering: optimizeLegibility;
  background: #f5f5f5;
  font-family: Roboto, sans-serif;
  display: flex;
}
.header-container {
  display: flex;
  height: 55px;
  width: 100%;
  background: darkcyan;
}
.body-container {
  display: flex;
  height: 100%;
  width: 100%;
}
.main-container {
  display: flex;
  padding: 0.5em 1em 0em 1em;
  background: #fff;
}
.page-title {
  margin: 48px 0px 24px;
  padding: 0 12px;
}
.mui-center-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
.login-btn-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mui-inner-grid {
  display: flex;
  background-color: #fff;
  padding: 20px;
  border: 3px solid #77777785;
  flex-direction: column;
  border-radius: 8px;
}
.inherit-bg-color input {
  background-color: inherit !important;
}
.cb-tf > label {
  font-size: 1em;
}
.cb-tf > div {
  font-size: 1.2em;
}
.text-center {
  text-align: center !important;
}
.heading-center {
  text-align: center;
  font-weight: 500 !important;
}
.mtop20 {
  margin-top: 20px !important;
}
.mtop15 {
  margin-top: 15px !important;
}
.mtop10 {
  margin-top: 10px !important;
}
.mtop5 {
  margin-top: 5px !important;
}

.urlLink:hover{
  color: rgb(217, 217, 224);
  text-decoration: underline;
}

.floatingBtn-left{
  position: relative;
  top: -15px;
  left: -10px;
}

.floatingBtn-right{
  position: relative;
  top: -15px;
  left: -10px;
}


.flex-end{
  justify-content: flex-end;
}

.w-full{
  width: 100%;
}