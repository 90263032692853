.snackbar {
  display: inline-block;
  position: fixed;
  background: #323232;
  color: #f1f1f1;
  min-height: 50px;
  min-width: 290px;
  padding: 16px 24px 12px;
  box-sizing: border-box;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 90%;
  transition: visibility 0.3s, transform 0.3s;
  transform: translateY(100px);
  visibility: hidden;
  will-change: transform;
}

.snackbar--visible {
  visibility: visible;
  transform: none;
}
