html,
body,
#root {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
}

.year {
  display: flex;
  height: fit-content;
  overflow-y: scroll;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.month {
  width: 47%;
  height: fit-content;
  min-height: 250px;
  margin: 20px 10px 20px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.25);
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
}

.month-name {
  color: #3174ad;
  font-weight: bold;
  text-align: center;
  font-size: x-large;
}

.day {
  display: inline-block;
  width: 14%;
  height: 14%;
  margin: 12;
  text-align: center;
  line-height: 14%;
  font-size: large;
}

.date {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  width: 14%;
  height: 28%;
  background: white;
  /* border-radius: 50px; */
  /* border: none; */
  outline: none;
  font-size: medium;
}

.date.event {
  width: 14%;
  height: 14%;
  background: #3174ad;
  border-radius: 50px;
  border: none;
  outline: none;
}

.date.in-month:hover {
  cursor: pointer;
  background: #3174ad;
}

.today {
  background: #eaf6ff;
}

.date.prev-month,
.date.next-month {
  color: grey;
}
